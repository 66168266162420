import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom';
import { API_URL_ADD_USER, AUTH_API_DOMAIN_URL } from '../../common/constants/ApiRoutes';
import axios from 'axios';



function Register() {




  const [provinces, setProvinces] = useState([]); 
  const [cites, setCites] = useState([]); 

  const [firstName, setFirstName] = useState([]);
  const [lastName, setLastName] = useState([]);
  const [email, setEmail] = useState([]);
  const [phone, setPhone] = useState([]);
  const [city, setCity] = useState([]);
  const [address, setAddress] = useState([]);
  const [experience, setExperience] = useState([]);
  const [skills, setSkills] = useState([]);
  const [resume, setResume] = useState([]);


  const [isLoading, setIsLoading] = useState(false); 
  
  // const token = localStorage.getItem('token'); // Store token in state
  // axios.defaults.headers.common['Authorization'] = "Bearer " + token 

  
          
      const addUser = async () => {
              
        setIsLoading(true);

        try {
          const response = await axios
          .post(AUTH_API_DOMAIN_URL+'register/user.add',{
            firstName:firstName,
            lastName:lastName,
            email:email,
            phone:phone,
            city:city,
            address:address,
            experience:experience,
            skills:skills,
            resume:resume
          })
          .then((res) => {
            console.log(res);
          });   
        } catch (error) {     
          console.log(error);

        } finally {
          setIsLoading(false); 
        }
      };   
    


      const getProvinces = async () => {
          
        setIsLoading(true);

        try {
          const response = await axios
          .get(AUTH_API_DOMAIN_URL+'register/province.get')
          .then((res) => {
            setProvinces(res.data.data);
            console.log(res);
          });   
        } catch (error) {     
          console.log(error);

        } finally {
          setIsLoading(false); 
        }
      };   
        
      const getCites = async (id) => {
          
        setIsLoading(true);

        try {
          const response = await axios
          .get(AUTH_API_DOMAIN_URL+'register/'+id+'/cites.get')
          .then((res) => {
            setCites(res.data.data);
            console.log(res);
          });   
        } catch (error) {
          console.log(error);

        } finally {
          setIsLoading(false); 
        }
      };   
  
    
    
    useEffect(() => {
      getProvinces();
      
    }, []);





  return (
    <>
    <div className="text-right fontYekan container mx-auto max-w-2xl p-4">
    <div className="header text-center mb-4">
        
        <h2 className="text-2xl font-bold inline-block">ثبت نام</h2>

        <Link to="/">
            <button className="absolute top-2 left-2 p-2 text-gray-500 hover:text-gray-700 focus:outline-none focus:ring-2 focus:ring-blue-500">
            <svg className="w-5 h-5" fill="none" stroke="currentColor" strokeWidth="2" viewBox="0 0 24 24">
                <path d="M6 18L18 6" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M6 6L18 18" strokeLinecap="round" strokeLinejoin="round" />
            </svg>
        </button>
        </Link>
      </div>

      <div className="bg-white shadow rounded-lg p-8">

      <div className="mb-4">
          <label for="first_name" className="block text-gray-700 font-bold mb-2">  نام</label>
          <input type="text" id="first_name" onChange={(event) => setFirstName(event.target.value)} className="w-full px-3 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500" />
        </div>

        <div className="mb-4">
          <label for="last_name" className="block text-gray-700 font-bold mb-2">نام خانوادگی  </label>
          <input type="text" onChange={(event) => setLastName(event.target.value)}  id="last_name" className="w-full px-3 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500" />
        </div>

        <div className="mb-4">
          <label htmlFor="email" className="block text-gray-700 font-bold mb-2">ایمیل  </label>
          <input type="email" onChange={(event) => setEmail(event.target.value)}  id="email" className="w-full px-3 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500" placeholder="مثال email@email.com" />
        </div>


        <div className="mb-4">
          <label for="phone" className="block text-gray-700 font-bold mb-2">شماره تلفن همراه</label>
          <input type="tel" onChange={(event) => setPhone(event.target.value)} id="phone" className="w-full px-3 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500" placeholder="مثال ۰۹۱۲۱۲۳۴۵۶۸۷" />
        </div>

        <div className="mb-4">
          <label for="province" className="block text-gray-700 font-bold mb-2">نام استان</label>
          <select id="province" onChange={(even) => getCites(even.target.value)} className="w-full px-3 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500">
            <option value="">شهر خود را انتخاب نمایید</option>
            {provinces.map((item) => 
              <option value={item.id}>{item.name}</option>
            )}
          </select>
        </div>

        <div className="mb-4">
          <label for="city" className="block text-gray-700 font-bold mb-2">نام شهر</label>
          <select onChange={(event) => setCity(event.target.value)}  id="city" className="w-full px-3 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500">
            <option value="">شهر خود را انتخاب نمایید</option>
            {cites && cites.map((item) => 
              <option value={item.id}>{item.name}</option>
            )}
          </select>
        </div>


        <div className="mb-4">
          <label for="address" className="block text-gray-700 font-bold mb-2">آدرس  </label>
          <input type="text" onChange={(event) => setAddress(event.target.value)} id="address" className="w-full px-3 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500" placeholder="مثال ۰۹۱۲۱۲۳۴۵۶۸۷" />
        </div>


        <div className="mb-4">
          <label for="experience" className="block text-gray-700 font-bold mb-2">سابقه کار  </label>
          <textarea  onChange={(event) => setExperience(event.target.value)} id="experience" className="w-full px-3 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"  />
        </div>


        <div className="mb-4">
          <label for="skills" className="block text-gray-700 font-bold mb-2">مهارت ها   </label>
          <textarea onChange={(event) => setSkills(event.target.value)}  id="skills" className="w-full px-3 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"  />
        </div>

        <div className="mb-4">
          <label for="resume" className="block text-gray-700 font-bold mb-2"> پیوست رزومه   </label>
          <input onChange={(event) => setResume(event.target.value)}  type="file" id="resume" className="w-full px-3 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500" accept=".pdf, .doc, .docx"/>
        </div>


        <button onClick={() => addUser()} className="w-full bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 rounded-md shadow">ثبت نام</button>


      </div>
    </div>
    </>
  )
}

export default Register
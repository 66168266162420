import React, { createContext, useState } from 'react';
import { Route, Routes } from 'react-router-dom';
import  Home from "./home";
import  Intro from "./auth/Index";
import  Login from "./auth/Login";
import  Register from "./auth/register";
import ListTasks from "../pages/home/listTasks";
import DetailTask from "../pages/home/detailTask";
import Checkout from "../pages/home/checkout";
import Invoice from "../pages/home/invoice";

export const AppContext = createContext(null);



function App() {


  const [isLogin, setIsLogin] = useState(false);
  const [jwtToken, setJwtToken] = useState("");

  return (
    <AppContext.Provider value={{isLogin, setIsLogin, jwtToken, setJwtToken}}>
      <Routes>                  
        <Route path='/login' element={<Login />} />
        <Route path='/' element={<Intro />} />
        <Route path='/home' element={<Home />} />
        <Route path='/register' element={<Register />} />
        <Route path='/task-manager' element={<ListTasks />} />
        <Route path='/task/:id' element={<DetailTask />} />
        <Route path='/task/checkout/:id' element={<Checkout />} />
        <Route path='/task/:id/invoice' element={<Invoice />} />
      </Routes>
    </AppContext.Provider>
    
  );
}

export default App;

import React, { useEffect, useState } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import IconBackRight from '../../components/Icon/IconBackRight'
import axios from 'axios';
import { AUTH_API_DOMAIN_URL } from '../../common/constants/ApiRoutes';
import IconLoding from '../../components/Icon/IconLoding';

function Invoice() {


    const { id } = useParams();
    const [isLoading, setIsLoading] = useState(false);
    const token = localStorage.getItem('token');
    const [wages, setWages] = useState('');
    const [amountParts, setAmountParts] = useState('');
    const [parts, setParts] = useState('');
    const [orderDescription, setOrderDescription] = useState('');
    const [companyCommission, setCompanyCommission] = useState('');
    const navigate = useNavigate();

    const finishWork = (status) => {
          
        setIsLoading(true);
  
          try {
            axios
            .get(AUTH_API_DOMAIN_URL+id+'/' + 6 + '/accept.get',{},{Authorization: `Bearer ${token}`})
            .then((res) => {
              
              if(res.data.ok === true)  {        
                navigate('/task/'+id)
              }
              setIsLoading(false); 
            });   
          } catch (error) {     
            console.log(error);
          } 
      }



    const checkout = () => {
        setIsLoading(true);       
        try {
          axios
          .post(AUTH_API_DOMAIN_URL + id + '/checkout.get',
          {},{
              Authorization: `Bearer ${token}`,
              'Content-Type': 'application/json'})
          .then((res) => {                       
            if(res.data.ok === true)  {                
                setWages(res.data.data.wages)
                setAmountParts(res.data.data.amount_parts)
                setParts(res.data.data.parts)
                setOrderDescription(res.data.data.order_description)
                setCompanyCommission(res.data.data.company_commission)
            }
            setIsLoading(false); 
          });   
        } catch (error) {     
          console.log(error);
        } 
        console.log(amountParts);  
    }


      useEffect(() => {
        checkout();
      }, [token]);
  



  return (
    <>
        <nav className="fixed w-full top-0 bg-white shadow-md fontYekan text-base text-gray-600 p-4 flex ">
            <Link to={`/task/checkout/${id}`} >
                <IconBackRight />
            </Link>
            <div className='mr-5'>تسویه حساب</div>
        </nav>

        <div className='mx-3 my-16  fontYekan'>
            
                
            <table className="w-full text-sm text-right rtl:text-center text-gray-500 dark:text-gray-400 my-4  overflow-x-auto shadow-md sm:rounded-lg">
                <thead className=" text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                    <tr>
                        <th scope="col" className="px-6 py-3">
                            زمان شروع
                        </th>
                        <th scope="col" className="px-6 py-3">
                        زمان پایان
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr className="fontYekan odd:bg-white odd:dark:bg-gray-900 even:bg-gray-50 even:dark:bg-gray-800 border-b dark:border-gray-700">
                        <th scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                        </th>
                        <td className="px-6 py-4">
                            
                        </td>
                    </tr>
                </tbody>
            </table>

            <div className='flex flex-col bg-slate-100 h-full w-full rounded-md my-10 '>
                <div className=' bg-white shadow-lg rounded-md '>
                    <p className='flex justify-center my-1'><h1 className='font-bold'>650,000</h1><small className='mr-2'>تومان</small></p>
                    <p className='flex justify-center my-1 text-emerald-500'>مبلغ بالا به اعتبار شما افزوده خواهد شد</p>
                    <p className='flex justify-center my-1'><h1 className=''>روش پرداخت : </h1><h2 className='font-bold'>اعتباری</h2></p>
                    <p className='my-10'>                        

                        <div class="relative overflow-x-auto">
                            <table class="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
                                <tbody>
                                    <tr class="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                                        <th scope="row" class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                                            دستمزد تعمیرکار
                                        </th>
                                        <td class="px-6 py-4">
                                           {wages}
                                        </td>
                                    </tr>
                                    <tr class="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                                        <th scope="row" class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                                            سهم تعمیرکار
                                        </th>
                                        <td class="px-6 py-4">
                                            {wages - companyCommission}
                                        </td>
                                    </tr>  
                                    <tr class="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                                        <th scope="row" class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                                            هزینه قطعات
                                        </th>
                                        <td class="px-6 py-4">
                                            {amountParts}
                                        </td>
                                    </tr>  
                                    <tr class="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                                        <th scope="row" class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                                             تخفیف
                                        </th>
                                        <td class="px-6 py-4">
                                            0
                                        </td>
                                    </tr> 
                                    <tr class="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                                        <th scope="row" class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                                            کمیسیون جت تعمیر 
                                        </th>
                                        <td class="px-6 py-4">
                                            {companyCommission}
                                        </td>
                                    </tr>   
                                    <tr class="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                                        <th scope="row" class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                                             هزینه کل  
                                        </th>
                                        <td class="px-6 py-4">
                                            {wages + amountParts} 
                                        </td>
                                    </tr>                                   
                                </tbody>
                            </table>
                        </div>
                    </p>

                </div>
            </div>


            <div className='flex flex-col h-full w-full rounded-md my-10'>
            <Link to={`/task/checkout/${id}`} class="py-2.5 px-5 me-2 mb-2 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-100 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700 text-center">ویرایش مبالغ</Link>
            <button onClick={() => finishWork()} class="focus:outline-none text-white bg-green-700 hover:bg-green-800 focus:ring-4 focus:ring-green-300 font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2 dark:bg-green-600 dark:hover:bg-green-700 dark:focus:ring-green-800 text-center">اتمام کار</button>
            </div>

        </div>
    </>
  )
}

export default Invoice
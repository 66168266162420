import React, { useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import { IconArrowLeft } from '../../components/Icon';
import IconBackRight from '../../components/Icon/IconBackRight';
import axios from 'axios';
import { AUTH_API_DOMAIN_URL } from '../../common/constants/ApiRoutes';
import RequestUtils from '../../services/utils/RequestUtils';
import IconLoding from '../../components/Icon/IconLoding';


function DetailTask() {
    const { id } = useParams();

    const token = localStorage.getItem('token'); // Store token in state
    const [task, setTask] = useState([]); // Store fetched tasks in state
    const [isLoading, setIsLoading] = useState(false); // Track loading state
    const [status, setStatus] = useState({});
    const requestUtils = new RequestUtils();



    const nextStep = (statusStep) => {
          
      setIsLoading(true);

        try {
          axios
          .get(AUTH_API_DOMAIN_URL+id+'/' + statusStep + '/accept.get',{},{Authorization: `Bearer ${token}`})
          .then((res) => {
            
            if(res.data.ok === true)  {        
              setStatus(requestUtils.getRequestStatus(parseInt(res.data.data)));
              console.log(res.data);
            }
            setIsLoading(false); 
          });   
        } catch (error) {     
          console.log(error);
        } 
    }



    const rejectWork = () => {
      setIsLoading(true);

      try {
        axios
        .get(AUTH_API_DOMAIN_URL+id+'/8/accept.get',{},{Authorization: `Bearer ${token}`})
        .then((res) => {
          
          if(res.data.ok === true)  {        
            setStatus(requestUtils.getRequestStatus(parseInt(res.data.data)));
            console.log(res.data);
          }
          setIsLoading(false); 
        });   
      } catch (error) {     
        console.log(error);
      } 
    }



    const getTask = () => {
      
      setIsLoading(true);

      try {
        const response = axios
        .get(AUTH_API_DOMAIN_URL+id+'/detailTask.get',{},{Authorization: `Bearer ${token}`})
        .then((res) => {
          setTask(res.data.data);
          if(res.data.data.status)
            setStatus(requestUtils.getRequestStatus(parseInt(res.data.data.status)));
         // console.log(res);
        });   
      } catch (error) {     
        console.log(error);

      } finally {
        setIsLoading(false); 
      }
    };  

    useEffect(() => {
      getTask();
    }, [token,status]);


  return (
    <>
        <nav className="fixed w-full top-0 bg-white shadow-md fontYekan text-base text-gray-600 p-4 flex ">
            <Link to="/task-manager" >
                <IconBackRight />
            </Link>
            <div className='mr-2'>مشاهده جزيیات کار</div>
        </nav>

          
        <div className='mt-20'>
            
              <div className='m-3 shadow-md grid-rows-12 rounded-sm fontYekan'>
                  <div className='text-right m-3 text-blue-500 font-bold'>
                    جزيیات کار:
                  </div>
                  <div className='relative overflow-x-auto'>
                      <table className='w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400'>
                        <tbody>
                            <tr className="bg-white dark:bg-gray-800">
                                <th scope="row" className="px-6 py-2 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                                    نام دستگاه:
                                </th>
                                <td className="px-3 py-2">
                                    {task.device ? task.device.name : "" }
                                </td>
                            </tr>
                            <tr className="bg-white dark:bg-gray-800">
                                <th scope="row" className="px-6 py-2 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                                   برند دستگاه:
                                </th>
                                <td className="px-3 py-2">
                                {task.device_brand ? task.device_brand.name : ""}
                                </td>
                            </tr>
                            <tr className="bg-white dark:bg-gray-800">
                                <th scope="row" className="px-6 py-2 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                                    مشکل دستگاه:
                                </th>
                                <td className="px-3 py-2">
                                {task.device_problem}
                                </td>
                            </tr>
                        </tbody>
                      </table>
                  </div>
              </div>

              <div className='m-3 my-5 shadow-md grid-rows-12 rounded-sm fontYekan'>
                  <div className='text-right m-3 text-blue-500 font-bold'>
                      مشخصات درخواست کننده:
                  </div>
                  <div className='relative overflow-x-auto'>
                      <table className='w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400'>
                        <tbody>
                            <tr className="bg-white dark:bg-gray-800">
                                <th scope="row" className="px-6 py-2 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                                    نام :
                                </th>
                                <td className="px-3 py-2">
                                    {task ? task.name : "" }
                                </td>
                            </tr>

                            <tr className="bg-white dark:bg-gray-800">
                                <th scope="row" className="px-6 py-2 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                                    تلفن :
                                </th>
                                <td className="px-3 py-2">
                                    <a href='tel:{task.phone}'>{task ? task.phone : "" }</a>
                                </td>
                            </tr>

                            <tr className="bg-white dark:bg-gray-800">
                                <th scope="row" className="px-6 py-2 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                                    استان :
                                </th>
                                <td className="px-3 py-2">
                                    {task.city ? task.city.province.name : ""}
                                </td>
                            </tr>
                            <tr className="bg-white dark:bg-gray-800">
                                <th scope="row" className="px-6 py-2 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                                   شهر :
                                </th>
                                <td className="px-3 py-2">
                                {task.city ? task.city.name : ""}
                                </td>
                            </tr>
                            <tr className="bg-white dark:bg-gray-800">
                                <th scope="row" className="px-6 py-2 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                                    آدرس :
                                </th>
                                <td className="px-3 py-2">
                                {task.address}
                                </td>
                            </tr>
                            <tr className="bg-white dark:bg-gray-800">
                                <th scope="row" className="px-6 py-2 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                                    لوکیشن :
                                </th>
                                <td className="px-3 py-2">
                                {task.location}
                                </td>
                            </tr>

                            
                        </tbody>
                      </table>
                      <div className='flex justify-center my-3'>
                        <a href={"tel:" + task.phone} className="inline-flex items-center justify-center p-5 text-base font-medium text-gray-500 rounded-lg bg-gray-50 hover:text-gray-900 hover:bg-gray-100 dark:text-gray-400 dark:bg-gray-800 dark:hover:bg-gray-700 dark:hover:text-white">
                            <svg aria-hidden="true" className="w-5 h-5 me-3" viewBox="0 0 22 31" fill="none" xmlns="http://www.w3.org/2000/svg"><g clip-path="url(#clip0_4151_63004)"><path d="M5.50085 30.1242C8.53625 30.1242 10.9998 27.8749 10.9998 25.1035V20.0828H5.50085C2.46546 20.0828 0.00195312 22.332 0.00195312 25.1035C0.00195312 27.8749 2.46546 30.1242 5.50085 30.1242Z" fill="#0ACF83"/><path d="M0.00195312 15.062C0.00195312 12.2905 2.46546 10.0413 5.50085 10.0413H10.9998V20.0827H5.50085C2.46546 20.0827 0.00195312 17.8334 0.00195312 15.062Z" fill="#A259FF"/><path d="M0.00195312 5.02048C0.00195312 2.24904 2.46546 -0.000244141 5.50085 -0.000244141H10.9998V10.0412H5.50085C2.46546 10.0412 0.00195312 7.79193 0.00195312 5.02048Z" fill="#F24E1E"/><path d="M11 -0.000244141H16.4989C19.5343 -0.000244141 21.9978 2.24904 21.9978 5.02048C21.9978 7.79193 19.5343 10.0412 16.4989 10.0412H11V-0.000244141Z" fill="#FF7262"/><path d="M21.9978 15.062C21.9978 17.8334 19.5343 20.0827 16.4989 20.0827C13.4635 20.0827 11 17.8334 11 15.062C11 12.2905 13.4635 10.0413 16.4989 10.0413C19.5343 10.0413 21.9978 12.2905 21.9978 15.062Z" fill="#1ABCFE"/></g><defs><clipPath id="clip0_4151_63004"><rect width="22" height="30.1244" fill="white" transform="translate(0 -0.000244141)"/></clipPath></defs></svg>                                              
                            <span className="w-full">تماس با درخواست کننده</span>
                            <svg className="w-4 h-4 ms-2 rtl:rotate-180" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 10">
                                <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M1 5h12m0 0L9 1m4 4L9 9"/>
                            </svg>
                        </a>
                      </div> 
                  </div>
              </div>

              <div className='m-3 my-5 shadow-md grid-rows-12 rounded-sm fontYekan'>
                  <div className='text-right m-3 text-blue-500 font-bold'>
                        وضعیت کار:
                  </div>
                  <div className='relative overflow-x-auto'>
                      <table className='w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400'>
                        <tbody>
                            <tr className="bg-white dark:bg-gray-800">
                                <th scope="row" className="px-6 py-2 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                                    وضعیت :
                                </th>
                                <td className="px-3 py-2 text-center">                                    
                                    <div className={status ? status.className : ""}>{status ? status.titleKey : ""}</div>
                                </td>
                            </tr>

                            <tr className="bg-white dark:bg-gray-800">
                                <th scope="row" className="px-6 py-2 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                                    زمان درخواست :
                                </th>
                                <td className="px-3 py-2">
                                    <a href='tel:{task.phone}'>{task ? task.create_at : "" }</a>
                                </td>
                            </tr>

                            <tr className="bg-white dark:bg-gray-800">
                                <th scope="row" className="px-6 py-2 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                                    آخرین بروزرسانی :
                                </th>
                                <td className="px-3 py-2">
                                    {task.city ? task.update_at : ""}
                                </td>
                            </tr>
                            <tr className="bg-white dark:bg-gray-800">
                                <th scope="row" className="px-6 py-2 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                                   تاریخ ارجاع :
                                </th>
                                <td className="px-3 py-2">
                                {task.assigned_at}
                                </td>
                            </tr>
                        </tbody>
                      </table>
                  </div>
              </div>

              <div className='mx-3 my-5  fontYekan'>

                <div className="grid grid-cols-1 gap-4 relative bottom-0 ">
                  {(isLoading === false) ? 
                    <>


                      {status.id === 1 && 
                        <button onClick={() => nextStep(2)} type="button" className="focus:outline-none text-white bg-green-700 hover:bg-green-800 focus:ring-4 focus:ring-green-300 font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2 dark:bg-green-600 dark:hover:bg-green-700 dark:focus:ring-green-800">تایید شده توسط مشتری</button>
                      }

                      {status.id === 2 && 
                        <button onClick={() => nextStep(3)} type="button" className="focus:outline-none text-white bg-green-700 hover:bg-green-800 focus:ring-4 focus:ring-green-300 font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2 dark:bg-green-600 dark:hover:bg-green-700 dark:focus:ring-green-800">در مسیر به سمت مشتری</button>
                      }

                      {status.id === 3 && 
                        <button onClick={() => nextStep(4)} type="button" className="focus:outline-none text-white bg-green-700 hover:bg-green-800 focus:ring-4 focus:ring-green-300 font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2 dark:bg-green-600 dark:hover:bg-green-700 dark:focus:ring-green-800"> در حال انجام</button>
                      }

                      {status.id === 4 && 
                        <button onClick={() => nextStep(5)} type="button" className="focus:outline-none text-white bg-green-700 hover:bg-green-800 focus:ring-4 focus:ring-green-300 font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2 dark:bg-green-600 dark:hover:bg-green-700 dark:focus:ring-green-800"> انجام شد</button>
                      }

                      {status.id === 5 && 
                        <Link to={'/task/checkout/' + id} className="focus:outline-none text-white bg-green-700 hover:bg-green-800 focus:ring-4 focus:ring-green-300 font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2 dark:bg-green-600 dark:hover:bg-green-700 dark:focus:ring-green-800 text-center">
                           تسویه حساب
                        </Link>
                      }

                      {status.id === 1 && 
                        <button onClick={() => rejectWork()} type="button" className="focus:outline-none text-white bg-red-700 hover:bg-red-800 focus:ring-4 focus:ring-red-300 font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2 dark:bg-red-600 dark:hover:bg-red-700 dark:focus:ring-red-900">رد کار</button>
                      }
                    </>
                      :
                      <div className='flex justify-center my-8'><IconLoding /> </div> 
                    }
                </div>

              </div>
             
        </div>
          
        
    </>
  )
}

export default DetailTask
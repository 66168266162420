import React, { useContext, useState } from 'react';

import { BackButton } from '../../components';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { AppContext } from '../App';
import { API_URL_SIGN_IN } from '../../common/constants/ApiRoutes';

function Login() {
  const navigate = useNavigate();
  const { isLogin, setIsLogin, setJwtToken } = useContext(AppContext);
  const [phone, setPhone] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const loginCheck = async (e) => {
    e.preventDefault(); // Prevent default form submission behavior

    try {
      const response = await axios.post(API_URL_SIGN_IN, {
        email: email,
        password: password
      });

      const token = response.data.token;
      setJwtToken(token);
      localStorage.setItem('token', token);
      setIsLogin(true); // Assuming successful login redirects to home
      navigate('/task-manager'); // Redirect to home page after successful login
    } catch (error) {
      console.error(error); // Handle errors appropriately, e.g., display error message to user
    }
  };

  return (
    <>
      {isLogin && navigate('/task-manager')} 

      <div className="fontYekan text-right container mx-auto px-4 pb-12">
        <div className="flex flex-col items-center">
          <p className="mt-20 text-gray-500">متخصصان جت تعمیر</p>
          <Link to="/">
            <BackButton />
          </Link>
        </div>

        <div className="flex flex-col items-center">
          <form onSubmit={loginCheck} className="flex flex-col w-full max-w-sm mb-8">
            <label className="text-gray-700 mb-2" htmlFor="email">
              ایمیل  
            </label>
            <input
              type="email"
              id="email"
              value={email} // Access the state value directly
              onChange={(e) => setEmail(e.target.value)}
              className="border border-gray-300 rounded-md px-4 py-2"
            />

            <label className="text-gray-700 mb-2" htmlFor="password">
              رمز عبور
            </label>
            <input
              type="password"
              id="password"
              value={password} // Access the state value directly
              onChange={(e) => setPassword(e.target.value)}
              className="border border-gray-300 rounded-md px-4 py-2"
            />

            <button type="submit" className="bg-blue-500 text-white rounded-md px-4 py-2 mt-4">
              ورود
            </button>
          </form>

          <button type="button" className="bg-gray-200 text-gray-700 rounded-md px-4 py-2">
            ایجاد رمز عبور
          </button>
        </div>
      </div>
    </>
  );
}

export default Login;

import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faWallet } from '@fortawesome/free-solid-svg-icons';

function BtnIconWallet() {
  return (
    <>    
        <button data-tooltip-target="tooltip-home" type="button" className="inline-flex flex-col items-center justify-center px-5 rounded-s-full hover:bg-gray-50 dark:hover:bg-gray-800 group">
        <FontAwesomeIcon icon={faWallet} />
        <label className='text-nowrap text-xs fontYekan mt-1'> مالی </label>
        </button>
    </>
  )
}

export default BtnIconWallet
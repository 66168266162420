import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faThLarge } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';


function TaskManager() {
  return (
    <>
    <Link to='/task-manager' className=''>    
    <button className="p-3 inline-flex flex-col items-center justify-center px-5 rounded-s-full hover:bg-sky-600 dark:hover:bg-gray-800 group bg-blue-600 text-slate-100 rounded-full ">
      <FontAwesomeIcon icon={faThLarge} />
      <label className='text-nowrap text-xs fontYekan mt-1'>مدیریت کارها</label>
    </button>
    </Link>
  </>
  )
}

export default TaskManager
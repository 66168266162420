import React, { useContext } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import "./styles.css"
import { AppContext } from '../App';


function Intro() {
  const navigate = useNavigate();
  const {isLogin} = useContext(AppContext)

    return (
      <>
      {!isLogin ?
        <section className="h-screen">
          <div className="h-full">
            {/* <!-- Left column container with background--> */}
            <div className="g-6 flex h-full flex-wrap items-center justify-center lg:justify-between">
              <div className="shrink-1 mb-12 grow-0 basis-auto md:mb-0 md:shrink-0 lg:w-6/12 xl:w-6/12 sm:w-12/12">
                <img
                  src="https://tecdn.b-cdn.net/img/Photos/new-templates/bootstrap-login-form/draw2.webp"
                  className="w-full"
                  alt="Sample image"
                />
              </div>
              
              <div className="mb-12 md:mb-0 md:w-6/12 lg:w-4/12 xl:w-4/12">

                  <div className="flex flex-col items-center justify-center lg:justify-start">
                    <img
                        src="images/logo/logo-light.jpeg"
                        className="w-full h-20 w-20"
                        alt="logo"
                    />
                    <p className="mb-0 mr-4 text-lg">متخصصین جت تعمیر</p>
                  </div>    

                  <div className="my-4 flex items-center before:mt-0.5 before:flex-1 before:border-t before:border-neutral-300 after:mt-0.5 after:flex-1 after:border-t after:border-neutral-300"></div>                  
    
                  
                  <div className="text-center lg:text-left ">
                    <Link to="/register">
                      <button className="btn-register w-full text-white font-bold py-2 px-4 rounded">
                        ثبت نام
                      </button>
                    </Link>
                    <Link to="/login">
                      <button className="mt-4 bg-transparent hover:bg-teal-500 text-teal-600 font-semibold hover:text-white py-2 w-full border border-teal-500 hover:border-transparent rounded">
                      ورود
                      </button>
                    </Link>                    
                  </div>

              </div>
            </div>
          </div>
        </section>
        :
        navigate('/task-manager')
      }
      </>
      );
}

export default Intro
import React, { useEffect, useState } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import IconBackRight from '../../components/Icon/IconBackRight'
import axios from 'axios';
import { AUTH_API_DOMAIN_URL } from '../../common/constants/ApiRoutes';
import IconLoding from '../../components/Icon/IconLoding';

function Checkout() {


    const { id } = useParams();
    const [isLoading, setIsLoading] = useState(false);
    const token = localStorage.getItem('token');
    const [wages, setWages] = useState('');
    const [amountParts, setAmountParts] = useState('');
    const [parts, setParts] = useState('');
    const [orderDescription, setOrderDescription] = useState('');
    const navigate = useNavigate();

    const checkoutCalcute = () => {

        setIsLoading(true);
       
        try {
          axios
          .post(AUTH_API_DOMAIN_URL + id + '/checkout.add',
          {
              wages: wages,
              amountParts: amountParts,
              parts: parts,
              orderDescription: orderDescription
          },{
              Authorization: `Bearer ${token}`,
              'Content-Type': 'application/json'})
          .then((res) => {              
            if(res.data.ok === true)  {                
                navigate('/task/'+id+'/invoice');
            }
            setIsLoading(false); 
          });   
        } catch (error) {     
          console.log(error);
        } 
      }



    const checkout = () => {
        setIsLoading(true);       
        try {
          axios
          .post(AUTH_API_DOMAIN_URL + id + '/checkout.get',
          {},{
              Authorization: `Bearer ${token}`,
              'Content-Type': 'application/json'})
          .then((res) => {                       
            if(res.data.ok === true)  {                
                setWages(res.data.data.wages)
                setAmountParts(res.data.data.amount_parts)
                setParts(res.data.data.parts)
                setOrderDescription(res.data.data.order_description)
            }
            setIsLoading(false); 
          });   
        } catch (error) {     
          console.log(error);
        } 
        console.log(amountParts);  
    }


      useEffect(() => {
        checkout();
      }, [token]);
  



  return (
    <>
        <nav className="fixed w-full top-0 bg-white shadow-md fontYekan text-base text-gray-600 p-4 flex ">
            <Link to={`/task/${id}`} >
                <IconBackRight />
            </Link>
            <div className='mr-5'>تسویه حساب</div>
        </nav>

        <div className='mx-3 my-16  '>
            
                
            <table className="w-full text-sm text-right rtl:text-center text-gray-500 dark:text-gray-400 my-4  overflow-x-auto shadow-md sm:rounded-lg">
                <thead className="fontYekan text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                    <tr>
                        <th scope="col" className="px-6 py-3">
                            زمان شروع
                        </th>
                        <th scope="col" className="px-6 py-3">
                        زمان پایان
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr className="fontYekan odd:bg-white odd:dark:bg-gray-900 even:bg-gray-50 even:dark:bg-gray-800 border-b dark:border-gray-700">
                        <th scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                        </th>
                        <td className="px-6 py-4">
                            
                        </td>
                    </tr>
                </tbody>
            </table>

            <form className="mx-2 my-5 bg-white shadow-md sm:rounded-lg">

                <div className="relative z-0 w-full mb-5 group">
                    <input type="number" maxLength={9} max={9} onChange={(event) => setWages(event.target.value)} defaultValue={wages} name="wages" id="wages" className="block py-2.5 px-3 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer" placeholder=" " required />
                    <label htmlFor="wages" className="fontYekan pr-5 peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">دستمزد انجام کار </label>
                </div>

                <div className="grid md:grid-cols-2 md:gap-6">
                    <div className="relative z-0 w-full mb-5 group">
                        <input type="number" maxLength={9} max={9}  onChange={(event) => setAmountParts(event.target.value)} defaultValue={amountParts} name="amount_parts" id="amount_parts" className="block py-2.5 px-3 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer" placeholder=" " required />
                        <label htmlFor="amount_parts" className="fontYekan pr-5 peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6 ">هزینه قطعات استفاده شده </label>
                    </div>
                    <div className="relative z-0 w-full mb-5 group">
                        <textarea rows='5' onChange={(event) => setParts(event.target.value)} defaultValue={parts} name="parts" id="parts" className="block py-2.5 px-3 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer" placeholder=" " ></textarea>
                        <label htmlFor="parts" className="fontYekan pr-5 peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">قطعات استفاده شده </label>
                    </div>
                </div>

                <div className="grid md:grid-cols-2 md:gap-6">                    
                    <div className="relative z-0 w-full mb-5 group">
                        <textarea rows='5' onChange={(event) => setOrderDescription(event.target.value)} defaultValue={orderDescription} name="order_description" id="order_description" className="block py-2.5 px-3 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer" placeholder=" "  ></textarea>
                        <label htmlFor="order_description" className="fontYekan pr-5 peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"> سایر توضیحات </label>
                    </div>
                </div>

                <div className="grid md:grid-cols-2 md:gap-6 m-5 ">
                    <div className="relative z-0 w-full mb-5 group">   
                        {
                        (isLoading === false) ? 
                            <button type="button" onClick={() => checkoutCalcute()} className="fontYekan text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">محاسبه هزینه</button>
                        :
                        <div className='flex justify-center'><IconLoding /> </div> 
                        }
                    </div>
                </div>

            </form>



        </div>
    </>
  )
}

export default Checkout
import React, { useContext } from 'react'
import { BtnIconMessage, BtnIconProfile, BtnIconTaskManager, BtnIconWallet } from '../../components'
import { AppContext } from '../../pages/App'

function Navigation() {

  const {isLogin} = useContext(AppContext)


  return (
    <>
    
      {isLogin && 
        <>
        is login
        </>
      }
      <div className="shadow-sm fixed z-50 w-full h-16 max-w-lg -translate-x-1/2 bg-white border border-gray-200 rounded-full bottom-4 left-1/2 dark:bg-gray-700 dark:border-gray-600">
          <div className="grid h-full max-w-lg grid-cols-4 mx-auto">
              <BtnIconTaskManager /> 
              <BtnIconMessage />
              <BtnIconWallet />              
              <BtnIconProfile />  
          </div>
      </div>
    </>
  )
}

export default Navigation
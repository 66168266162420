import React, { useEffect, useState } from 'react'
import { Navbar } from '../../layouts'
import { IconArrowLeft } from '../Icon'
import { Link } from 'react-router-dom'
import { API_URL_GET_MY_TASKS } from '../../common/constants/ApiRoutes';
import axios from 'axios';

function TaskCard() {

    // const navigate = useNavigate();

    const [tasks, setTasks] = useState([]); // Store fetched tasks in state
    const [isLoading, setIsLoading] = useState(false); // Track loading state
    
    const token = localStorage.getItem('token'); // Store token in state
    axios.defaults.headers.common['Authorization'] = "Bearer " + token 

    
      const getTasks = async () => {
        
        setIsLoading(true);

        try {
          const response = await axios
          .get(API_URL_GET_MY_TASKS)
          .then((res) => {
            setTasks(res.data.data);
            console.log(res);
          });   
        } catch (error) {     
          console.log(error);

        } finally {
          setIsLoading(false); 
        }
      };   
      
      
      
      useEffect(() => {
        getTasks();
        
      }, [token]);



  return (
    
    <>
        <Navbar />
   

        <div className="mt-20 mb-20 grid md:mb-12 md:grid-cols-2 bg-white dark:bg-gray-800 fontYekan">
            {isLoading ? (
              <div className="text-center mt-20">در حال بارگذاری ...</div>
            ) : tasks && tasks.length > 0 ? (   
                <>  
                  {tasks.map((task) => (
                    <Link to={`/task/${task.id}`} >
                        <figure className="shadow-lg my-2 mx-4 border-solid border border-slate-300 flex flex-col items-center justify-right px-4 pt-3 text-right bg-white rounded-lg md:rounded-ss-lg dark:bg-gray-800 hover:bg-blue-100 ">
                            <div className="w-full mx-auto mb-4 lg:mb-8  text-gray-500 dark:text-gray-400 ">
                                <h3 className="text font-semibold text-gray-900 dark:text-white">{task.device.name} <small className='text-xs text-gray-400'>({task.device_brand.name})</small> : {task.device_problem} <small className='float-left border-solid border px-1  rounded-md border-blue-300'>کد: {task.tracking_number}</small></h3>
                                <h4 className="mt-4 text-xs font-medium text-gray-600 dark:text-white">آدرس : {task.city.province.name} - {task.city.name} - {task.region.name} - {task.address}</h4>
                            </div>
                            <div className='w-full '>
                                <div className='flex flex-row items-center text-right w-full mb-0 text-sm pt-4 pb-4' >
                                    <span className='w-full'>{task.create_at}</span>
                                    <span className='w-full flex-1'><IconArrowLeft  /></span>
                                </div>
                            </div>          
                        </figure>
                    </Link>
                  ))}
                </> 
              ) : (
                <div className="text-center mt-20">هیچ سفارشی یافت نشد</div>
              )}
            
            
       

        </div>

    </>

  )
}

export default TaskCard

export const REQUEST_STATUS_LIST = [
    {
      id: 0,
      titleKey: 'وضعیت نامشخص',
      className: "bg-gray-100 text-gray-800 text-xs font-medium me-2 px-2.5 py-2 rounded dark:bg-gray-700 dark:text-gray-400 border border-gray-500",
    },
    {
      id: 1,
      titleKey: 'دیده شده',
      className: "bg-blue-100 text-blue-800 text-xs font-medium me-2 px-2.5 py-2 rounded dark:bg-gray-700 dark:text-blue-400 border border-blue-400",
    },
    {
      id: 2,
      titleKey: 'تایید شده توسط مشتری',
      className: "bg-green-100 text-green-800 text-xs font-medium me-2 px-2.5 py-2 rounded dark:bg-green-900 dark:text-green-300",
    },
    {
      id: 3,
      titleKey: 'در مسیر به سمت مشتری',
      className: "bg-yellow-100 text-yellow-800 text-xs font-medium me-2 px-2.5 py-2 rounded dark:bg-gray-700 dark:text-yellow-300 border border-yellow-300",
    },
    {
      id: 4,
      titleKey: 'در حال انجام',
      className: "bg-purple-100 text-purple-800 text-xs font-medium me-2 px-2.5 py-2 rounded dark:bg-gray-700 dark:text-purple-400 border border-purple-400",
    },
    {
      id: 5,
      titleKey: 'انجام شد',
      className: "bg-green-100 text-green-800 text-sm font-medium me-2 px-2.5 py-2 rounded dark:bg-green-900 dark:text-green-300",
    },
    {
      id: 6,
      titleKey: 'تسویه شد',
      className: "bg-green-100 text-green-800 text-xs font-medium me-2 px-2.5 py-2 rounded dark:bg-gray-700 dark:text-green-400 border border-green-400",
    },
    {
      id: 7,
      titleKey: 'کار توسط مشتری لغو شد',
      className: "bg-red-100 text-red-800 text-xs font-medium me-2 px-2.5 py-2 rounded dark:bg-gray-700 dark:text-red-400 border border-red-400",
    },
    {
      id: 8,
      titleKey: 'کار توسط تعمیرکار لغو شد',
      className: "bg-pink-100 text-pink-800 text-sm font-medium me-2 px-2.5 py-2 rounded dark:bg-gray-700 dark:text-pink-400 border border-pink-400",
    },
    {
      id: 9,
      titleKey: 'خارج از محدوده',
      className: "bg-gray-100 text-gray-800 text-xs font-medium me-2 px-2.5 py-2 rounded dark:bg-gray-700 dark:text-gray-400 border border-gray-500",
    },
    {
      id: 10,
      titleKey: 'کار توسط تعمیرکار پذیرفته شد',
      className: "bg-indigo-100 text-indigo-800 text-xs font-medium me-2 px-2.5 py-2 rounded dark:bg-gray-700 dark:text-indigo-400 border border-indigo-400",
    },
  ];
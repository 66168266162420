import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const BackButton = () => {
  return (
    <button className="flex items-center justify-center bg-gray-200 text-gray-600 px-4 py-2 rounded-md hover:bg-gray-300">
      {/* <FontAwesomeIcon icon={['fas', 'arrow-left']} className="mr-2" /> */}
      بازگشت
    </button>
  );
};

export default BackButton;
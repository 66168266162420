const AUTH_DOMAIN = process.env.REACT_APP_REPAIRMAN_API_ENDPOINT;
const AUTH_API_DOMAIN = AUTH_DOMAIN + "api/";



export const AUTH_API_DOMAIN_URL = AUTH_API_DOMAIN;
export const API_URL_SIGN_IN = AUTH_API_DOMAIN + "login_check";
export const API_URL_GET_MY_TASKS = AUTH_API_DOMAIN + "myTask.get";
export const API_URL_GET_DETAIL_TASK = AUTH_API_DOMAIN + "detailTask.get";






import React from 'react'
import TaskCard from '../../components/Card/TaskCard'
import { Navigation } from '../../layouts'

function listTasks() {
  return (
    <>
        <TaskCard />

        <Navigation />
    </>
  )
}

export default listTasks
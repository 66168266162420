import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMessage } from '@fortawesome/free-solid-svg-icons';

function Message() {
  return (
    <>    
        <button data-tooltip-target="tooltip-home" type="button" className="inline-flex flex-col items-center justify-center px-5 rounded-s-full hover:bg-gray-50 dark:hover:bg-gray-800 group">
        <FontAwesomeIcon icon={faMessage} />
        <label className='text-nowrap text-xs fontYekan mt-1'> پیام ها</label>
        </button>
    </>
  )
}

export default Message
import React, { useContext } from 'react'
import { AppContext } from '../App'
import { Navbar, Navigation } from '../../layouts'

function Home() {

  const {isLogin} = useContext(AppContext)

  return (
    <>
    
      {isLogin && 
        <>
        is login
        </>
      }
     

      <Navigation />
    </>
  )
}

export default Home
import { REQUEST_STATUS_LIST } from "../../common/constants/Status";



export default class RequestUtils {



    getRequestStatus(status) {
        return REQUEST_STATUS_LIST.find((item) => item.id === status);
    }

}
import React from 'react';
import { Link } from 'react-router-dom';

function Menu() {
  return (
    <nav className="fixed w-full top-0 bg-white shadow-sm fontYekan text-base text-white p-4 flex justify-between border-2">
    <Link to="/task-manager" className="bg-blue-100 text-blue-800 text-base font-medium me-1 px-4 py-1.5 rounded-full dark:bg-blue-900 dark:text-blue-300">درخواست ها</Link>
        <Link to="#"   className="bg-blue-100 text-blue-800 text-base font-medium me-4 px-4 py-1.5 rounded-full dark:bg-blue-900 dark:text-blue-300">کارهای من</Link>
        <Link to="/requests" className="bg-blue-100 text-blue-800 text-base font-medium me-4 px-4 py-1.5 rounded-full dark:bg-blue-900 dark:text-blue-300">منتظر تایید</Link>
        <Link to="#"  className="bg-blue-100 text-blue-800 text-base font-medium me-4 px-4 py-1.5 rounded-full dark:bg-blue-900 dark:text-blue-300"> گذشته</Link>
    </nav>
  );
}

export default Menu;